import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BioLink from './components/bioLink/BioLink';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/bio" element={<BioLink />}/>
      </Routes>
    </Router>
  );
}

export default App;
